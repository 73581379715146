















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import { DRIVER_ANNOUNCEMENT_PAGINATION } from '@/app/infrastructures/misc/Constants/pagination'
import { Utils } from '@/app/infrastructures/misc'
import controller from '@/app/ui/controllers/DriverAnnouncementController'
import dayjs from 'dayjs'
import dayjsId from 'dayjs/locale/id'
import CloseCircle from '@/app/ui/assets/close_circle_alt.vue'
import TrashIcon from '@/app/ui/assets/trash_icon_alt.vue'
import EmptyIllustration from '@/app/ui/assets/EmptyState/no_data-paket.vue'
import { Location } from 'vue-router'
import { EnumDriverAnnouncementStatus, EnumDriverAnnouncementCourierType } from '@/app/infrastructures/misc/Constants/driverAnnouncement'
import { Dictionary } from 'vue-router/types/router'

interface Parameter {
  page: number
  perPage: number
}

interface Filter {
  start: string | Date
  end: string | Date
  status: { label: string, value: string }
  courierType: { label: string, value: string }
}

export interface IHeaderCell {
  title: number | string
  customStyle: IStyle
}

export interface IStyle {
  minWidth: string
  maxWidth: string
}

export interface ITableCell {
  value: string | number | Date | string[]
  customStyle: IStyle
}

@Component({
  components: {
    Button,
    DataTableV2,
    PaginationNav,
    LoadingOverlay,
    Modal,
    CloseCircle,
    Badge,
    TrashIcon,
    DropdownSelect,
    DateTimePicker,
    EmptyIllustration
  },
})
export default class DriverAnnouncementListPage extends Vue {
  isGuest = Utils.isGuest()
  controller = controller

  parameters: Parameter = {
    page: 1,
    perPage: DRIVER_ANNOUNCEMENT_PAGINATION,
  }

  filters: Filter = {
    start: '',
    end: '',
    courierType: { label: "Semua", value: '' },
    status: { label: 'Semua', value: '' } 
  }

  deleteConfirmation = false
  deleteId: number | null = null

  enumDriverAnnuoncementStatus = EnumDriverAnnouncementStatus
  enumDriverAnnuoncementCourierType = EnumDriverAnnouncementCourierType

  courierTypeFilterOptions = [
    { label: 'Semua', value: '' },
    { label: 'KVP', value: this.enumDriverAnnuoncementCourierType.KVP },
    { label: 'POS', value: this.enumDriverAnnuoncementCourierType.POS },
    { label: 'Driver Apps', value: this.enumDriverAnnuoncementCourierType.ALL },
    { label: 'Trucking Apps', value: this.enumDriverAnnuoncementCourierType.TRUCK },
  ]

  statusFilterOptions = [
    { label: 'Semua', value: '' },
    { label: 'Aktif', value: this.enumDriverAnnuoncementStatus.ACTIVE },
    { label: 'Menunggu', value: this.enumDriverAnnuoncementStatus.WAITING },
    { label: 'Kadaluarsa', value: this.enumDriverAnnuoncementStatus.EXPIRED },
    
  ]

  headers = [
    this.headerCellMapper('ID', '60px'),
    this.headerCellMapper('Judul', '240px'),
    this.headerCellMapper('Jenis Kurir', '120px'),
    this.headerCellMapper('Mulai', '140px'),
    this.headerCellMapper('Berakhir', '140px'),
    this.headerCellMapper('Status', '140px'),
    this.headerCellMapper('Aksi', '140px'),
  ]

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage:
          Utils.alwaysNumber(queries.perPage) || DRIVER_ANNOUNCEMENT_PAGINATION,
      }
    }

    this.fetchAnnouncementList()
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  get announcementDataTable(): ITableCell[][] {
    return controller.announcementData.map(announcement => [
      this.tableCellMapper(announcement.announcementId || '-','60px'),
      this.tableCellMapper(announcement.announcementTitle || '-', '240px'),
      this.tableCellMapper(this.formatedCourierType(announcement.announcementCourierType || '-'), '120px'),
      this.tableCellMapper(announcement.startAt ? Utils.formatDateWithIDLocale(announcement.startAt as string, 'DD MMMM YYYY') : '-', '140px'),
      this.tableCellMapper(announcement.endAt ? Utils.formatDateWithIDLocale(announcement.endAt as string, 'DD MMMM YYYY') : '-', '140px'),
      this.tableCellMapper(this.formatedStatus(announcement.status || '-'), '140px'),
      this.tableCellMapper(announcement.announcementId || '-', '140px'),
    ])
  }

  get params(): Record<string, string | number > {
    let paramsObj = {
      ...this.parameters,
      status: this.filters.status.value,
      announcement_courier_type: this.filters.courierType.value,
      start_date: this.filters.start ? dayjs(this.filters.start).locale(dayjsId).format('YYYY-MM-DD') : '',
      end_date: this.filters.end ? dayjs(this.filters.end).locale(dayjsId).format('YYYY-MM-DD') : '',
      time_zone: encodeURIComponent(Utils.formatDate(new Date().toDateString(), 'Z'))
    }
    return this.cleanEmptyParam(paramsObj)
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private cleanEmptyParam(
    obj: Record<string, string | number>
  ): Record<string, string | number > {
  for (var propName in obj) {
    if (
      obj[propName] === ''
    ) {
      delete obj[propName];
    }
  }
  return obj
}

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  private formatedCourierType(status: string): string {
    let result = '-'
    switch(status) {
      case this.enumDriverAnnuoncementCourierType.ALL:
        result = 'Driver Apps'
        break
      case this.enumDriverAnnuoncementCourierType.KVP:
        result = 'KVP'
        break
      case this.enumDriverAnnuoncementCourierType.POS:
        result = 'POS'
        break
      case this.enumDriverAnnuoncementCourierType.TRUCK:
        result = 'Trucking Apps'
        break
      case this.enumDriverAnnuoncementCourierType.SELECTED:
        result = 'SELECTED'
    }
    return result
  }

  private formatedStatus(status: string): string {
    let result = '-'
    switch(status) {
      case this.enumDriverAnnuoncementStatus.ACTIVE:
        result = 'Aktif'
        break;
      case this.enumDriverAnnuoncementStatus.EXPIRED:
        result = 'Kadaluarsa'
        break
      case this.enumDriverAnnuoncementStatus.WAITING:
        result = 'Menunggu'
        break
    }
    return result
  }

  private coloringStatus(status: string): string {
    let result = ''
    switch (status) {
      case 'Aktif':
        result = 'success'
        break
      case 'Kadaluarsa':
        result = 'error-1'
        break
      case 'Menunggu':
        result = 'warning'
        break
    }
    return result
  }

  private fetchAnnouncementList(resetPage = false): void {
    if (resetPage) {
      this.parameters.page = 1
    }

    controller.getAnnouncementList(this.params)
  }

  private toggleDeleteModal(id: null | number) {
    this.deleteId = id
    this.deleteConfirmation = id !== null
  }

  private async deleteAnnouncement() {
    if (this.deleteId) {
      this.parameters.page = 1
      await controller.deleteAnnouncement({id: this.deleteId, params: this.params})
    }
  }

  private routeTo(id: number, routeName: string): Location {
    return {
      name: routeName,
      params: { id: String(id) }
    }
  }

  @Watch('controller.isDeleteDriverAnnouncementSuccess')
  onIsDeleteDriverAnnouncementSuccessChanged(val: boolean): void {
    if (val) {
      this.$notify({
        title: 'Announcement deletion',
        text: 'Announcement is successfully deleted',
        type: 'success',
        duration: 5000,
      })

      this.deleteConfirmation = false
      this.deleteId = null
    }
    this.controller.setIsDeleteDriverAnnouncementSuccess(false)
  }

  beforeDestroy(): void {
    this.controller.setIsDeleteDriverAnnouncementSuccess(false)
  }
}
