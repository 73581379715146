export enum EnumDriverAnnouncementStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  WAITING = 'WAITING'
}

export enum EnumDriverAnnouncementCourierType {
  ALL = 'ALL',
  POS = 'POS',
  KVP = 'KVP',
  TRUCK = 'TRUCK',
  SELECTED = 'SELECTED',
}
